import { Label } from "components/UI/Label";

import { Link } from "@remix-run/react";
import { OptimizedImage } from "components/OptimizedImage";
import { ClintButton } from "components/shadcn-ui/button";
import { getArticles } from "server/strapi.server";
import { cn, useIsDevmode } from "utils/utils";
import { Paragraph } from "./Paragraph";
import { Tag } from "./Tag";

export const CardArticle = ({
  darkmode = false,
  className,
  textClassname,
  article,
}: {
  darkmode?: boolean;
  className?: string;
  textClassname?: string;
  article: Awaited<ReturnType<typeof getArticles>>["articles"][0];
}) => {
  const isDev = useIsDevmode();
  return (
    <div
      className={cn(
        `relative flex w-full grow flex-col overflow-hidden rounded-clint lg:basis-[442px]`,
        {
          "bg-secondary text-white": darkmode,
          "text-secondary bg-white": !darkmode,
        },
        className,
      )}
    >
      <OptimizedImage
        source={article.image.url}
        alt={article.image.alternativeText}
        containerClassName="mx-0 w-full max-h-[230px] overflow-hidden h-full"
        imageProps={{
          minHeight: "100%",
          objectFit: "cover",
        }}
        className="h-auto w-full"
        maxWidth={450}
        width={article.image.width}
        height={article.image.height}
      >
        <div className="absolute bottom-5 left-5 flex gap-1.5">
          {article.categories?.map((tag, index) => (
            <Tag type="Rounded" variant="Light" key={index}>
              {tag.name}
            </Tag>
          ))}
        </div>
      </OptimizedImage>
      <div
        className={cn(
          "z-10 flex h-full flex-col px-[30px] py-5",
          textClassname,
        )}
      >
        <div className="mb-5 flex flex-col gap-2">
          <Label variant={darkmode ? "white" : "secondary"} size={"S"} as="p">
            {article.datePublished}
          </Label>
          <Link to={`/blog/${article.slug}`}>
            <Label
              id={`article-link-${article.slug}`}
              as="h5"
              size={"L"}
              variant={darkmode ? "white" : "secondary"}
            >
              {article.title}
            </Label>
          </Link>
          <Paragraph
            variant={darkmode ? "white" : "secondary"}
            size={"sm"}
            fontWeight={"regular"}
            className="overflow-hidden text-ellipsis [-webkit-box-orient:vertical] [-webkit-line-clamp:4] [display:-webkit-box]"
          >
            {article.description}
          </Paragraph>
        </div>
        <ClintButton
          as="Link"
          linkProps={{
            to: `/blog/${article.slug}`,
            obfuscate: true,
          }}
          size="small"
          className="mt-auto w-fit"
          variant={darkmode ? "secondary" : "primary"}
          iconPosition="right"
        >
          Lire l'article
        </ClintButton>
      </div>

      <Link
        title={article.title}
        prefetch={isDev ? "none" : "intent"}
        aria-labelledby={`article-link-${article.slug}`}
        to={`/blog/${article.slug}`}
        className="absolute inset-0 z-20 opacity-0"
      >
        {article.title}
      </Link>
    </div>
  );
};
